import Slider from 'react-slick';
import useDiscountProducts from 'hooks/api/useDiscountProducts';
import useCategories from 'hooks/api/useCategories';
import Catalog from 'components/common/Catalog';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import classNames from 'classnames';
import { faDownload, faShield, faMessage, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'next-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { AppContext } from 'contexts/';
import { Quantico } from 'next/font/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import styles from './index.module.scss';

const TrustPilotWidget = dynamic(() => import('../../common/TrustPilotWidget'), {
  ssr: false,
});

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        speed: 500,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
      },
    },
  ],
};

const Home = () => {
  const { currency, categories } = useContext(AppContext);
  const { data: discountProducts = [], isLoading: discountLoading } = useDiscountProducts(currency);
  const { t } = useTranslation('home');

  const infoActions = useMemo(() => [
    { title: t('infoTitleFirst'), info: t('infoFirst'), icon: faDownload },
    { title: t('infoTitleSecond'), info: t('infoSecond'), icon: faShield },
    { title: t('infoTitleThird'), info: t('infoThird'), icon: faMessage },
  ], [t]);

  const renderItem = useCallback((item: Category, idx: number) => (
    <Catalog key={idx.toString()} {...item} route={`/category/${item.link}`} />
  ), []);

  const renderDiscountProduct = useCallback((item: Product.Item, idx: number) => (
    <Card {...item} key={idx.toString()} url={`/product/${item.link}`} btn />
  ), []);

  const categoriesIsLoading = useMemo(() => {
    if (discountLoading) return <div className={styles.loader}><Loader /></div>;
    return null;
  }, [discountLoading]);

  const discountIsLoading = useMemo(() => {
    if (!categories.length) return <div className={styles.loader}><Loader /></div>;
    return null;
  }, [categories]);

  return (
    <div>
      <Head>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,r,n){
            w.TrustpilotObject=n;
            w[n]=w[n]||function(){
              (w[n].q=w[n].q||[]).push(arguments)
            };
            a=d.createElement(s);a.async=1;a.src=r;a.type='text/java'+s;
            f=d.getElementsByTagName(s)[0];
            f.parentNode.insertBefore(a,f)
          })(window,document,'script','https://invitejs.trustpilot.com/tp.min.js','tp');
          tp('register', 'XzhTMOnXiuUCAKuc');
        `,
          }}
        />
      </Head>
      <meta name='google-site-verification' content='hlPuHWhDQtMgqrc79k0R_1IKiwPe9U09--Ha6kVLZBs' />
      <meta name='cryptomus' content='24c06f31' />
      <div className={styles.sliderTitleContainer}>
        <h1 className={classNames(styles.title, quantico.className)}>{t('titleDiscount')}</h1>
      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.wrapper}>
          {
            discountProducts.length
              ? (
                <Slider
                  className={styles.slider}
                  {...settings}
                >
                  {discountProducts.map(renderDiscountProduct)}
                </Slider>
              )
              : null
            }
          {categoriesIsLoading}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <h1 id='categories' className={classNames(styles.title, quantico.className)}>{t('categories')}</h1>
      </div>
      <div className={styles.catalogContainer}>
        {categories.map(renderItem)}
        {discountIsLoading}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.wrapper}>
          {infoActions.map((action, idx) => (
            <div key={idx.toString()} className={styles.block}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={action.icon}
              />
              <div className={styles.text}>
                <h2>{action.title}</h2>
                <span className={styles.textInfo}>{action.info}</span>
              </div>
            </div>
          ))}
          <TrustPilotWidget />
        </div>
      </div>
    </div>
  );
};

export default Home;
